import React, { useState } from "react";
import { Button, Card } from 'react-bootstrap';
import "./CardItem.scss";
import Icons from "../../../components/Icons/Icons";
import axios from 'axios';
import { useMessageError } from '../../../hooks/message.hook';
import { Link, useNavigate } from "react-router-dom";
import { getWishListOperation as getWishList } from "../../../store/wishList/operations";
import { useDispatch } from "react-redux";
import AddToCartButton from "../../AddToCartButton/AddToCartButton";
import PriceBlock from "../../PriceBlock/PriceBlock";
import defaultImg from '../../../theme/assets/images/pexels-rethaferguson-3059609.jpg';

const CardItem = ({ auth, item, wishProduct }) => {
    const messageError = useMessageError();
    const dispatch = useDispatch();
    const [iconColor, setIconColor] = useState(wishProduct[item._id] ? "#000000" : "");
    const navigate = useNavigate();
    const admin = auth.permissions?.admin?.access;

    const onSubmit = async (id) => {
        try {
            let mainUrl = "";
            const config = {};
            if (auth.isAuthenticated) {
                config.headers = { "Authorization": `Bearer ${auth.token}` };
                mainUrl = admin ? "admin" : "customer";
            }

            const response = await axios.post(`/api/${mainUrl}/wishlist/${id}`, id, config);

            // Update local storage with the new wishlist
            localStorage.setItem("wishlist", JSON.stringify(response.data.data));

            // Update wishProduct state
            const updatedWishProduct = {};
            response.data.data.products.forEach((prod) => {
                updatedWishProduct[prod._id] = true;
            });

            // Set icon color based on the wishlist state
            if (updatedWishProduct[id]) {
                setIconColor("#000000");
            } else {
                setIconColor("");
            }

            // Dispatch the updated wishlist
            dispatch(getWishList(admin));

        } catch (error) {
            messageError(error.response?.data?.message || "Something went wrong");
        }
    };

    const toProduct = (id) => {
        navigate(`/products/item/${id}`);
    };
    return (
        <Card className="goods-tile position-relative">
            {!!item.discount && 
                <div className="discount-badge">
                    {item.discount}%
                </div>
            }
            <Card.Img 
                className="card_image cursor-pointer" 
                onClick={() => toProduct(item._id)}
                variant="top" 
                src={item.imageFirst ? item.imageFirst : `${defaultImg}`}  
             />
            {auth.isAuthenticated && (
                <div key={item._id} style={{ cursor: 'pointer' }}>
                    <Icons
                        type="navHeart"
                        color="black"
                        onClick={() => onSubmit(item._id)}
                        filled={iconColor}
                        width={25}
                        height={25}
                    />
                </div>
            )}
            <Card.Body>
                <Card.Title className="cursor-pointer">
                    <Link to={`/products/item/${item._id}`}>{item.name}</Link>
                </Card.Title>
                <div className="d-flex justify-content-between">
                    <PriceBlock
                        className="mb-3"
                        previousPrice={item.previousPrice}
                        currentPrice={item.currentPrice}
                    />
                    <AddToCartButton
                        admin={!!admin}
                        product={item}
                        id={item._id}
                        orderButton={false}
                        currentPrice={item.currentPrice}
                    />
                </div>
            </Card.Body>
        </Card>
    );
};

export default CardItem;
