import React, { useContext, useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from 'react-redux'; // useSelector імпортовано один раз тут
import { Formik, Form, Field } from 'formik';
import { placeOrder } from '../../../http/ordersAPI';
import { deleteCart } from '../../../http/cartAPI';
import CustomerDataInputs from '../CustomerDataInputs/CustomerDataInputs';
import DeliveryDataInputs from '../DeliveryDataInputs/DeliveryDataInputs';
import PaymentDataInputs from '../PaymentDataInputs/PaymentDataInputs';
import { getUserIsLoadingSelector, getUserSelector } from '../../../store/user/selectors';
import Loader from '../../../components/Loader/Loader';
import schema from '../schema';
import styles from './CheckoutAuth.module.scss';
import { cartTotalPriceAction, saveCartAction, saveLocalCartAction } from '../../../store/cart/actions';
import { orderOpenOperation } from '../../../store/modal/operations';
import { Button } from 'react-bootstrap';
import { AuthContext } from "../../../context/AuthContext";
import { useMessageSuccess } from '../../../hooks/message.hook';
import { getLocalCartSelector } from '../../../store/cart/selectors'; // Імпорт інших селекторів

const CheckoutAuth = () => {
  const admin = useContext(AuthContext)?.permissions?.admin?.access || false;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [commentAvailible, setCommentAvailible] = useState(false);
  const customerLoading = useSelector(getUserIsLoadingSelector);
  const user = useSelector(getUserSelector);
  const messageSuccess = useMessageSuccess();
  const { _id: id } = user || {};
  const [products, setProducts] = useState([]);
  let localCart = useSelector(getLocalCartSelector);

  useEffect(() => {
    if (localCart.length) {
      setProducts(localCart);
    }
    else {
      navigate('/');
    }
  }, [localCart]);

  if (customerLoading) {
    return <Loader />;
  }

  return (
    <div className={styles.tabContainer}>
      <Formik
        initialValues={{
          firstName: user.firstName || '',
          lastName: user.lastName || '',
          phone: user.phone || '+380',
          email: user.email || '',
          region: '',
          city: '',
          delivery: '',
          address: '',
          paymentMethod: 'MonoPay',
          comment: '',
        }}
        validationSchema={schema}
        onSubmit={(values, { setSubmitting }) => {
          const { delivery, region, city, address, ...ordersValue } = values;
          const deliveryAddress = { delivery, region, city, address };

          const status = 'processed';

          // Використовуємо products зі стейту
          if (!products.length) {
            navigate('/');
            return;
          }

          placeOrder(admin, { ...ordersValue, deliveryAddress, userId: id, status, products })
            .then(res => {
              if (res.status === 200 || res.status === true || res.status === 201) {
                messageSuccess('Замовлення оформлене!');
                navigate('orderAccepted');
              }
              deleteCart().then(resCart => {
                localStorage.setItem('cart', JSON.stringify([]));
                dispatch(cartTotalPriceAction(0));
                dispatch(saveCartAction([]));
                dispatch(saveLocalCartAction([]));
                return resCart;
              });
            })
            .catch(err => {
              const message = Object.values(err.data).join('');
              dispatch(orderOpenOperation(message, true));
            });

          setSubmitting(false);
        }}
      >
        {formik => (
          <Form>
            <CustomerDataInputs formik={formik} />
            <h3 className='checkout__title'>Доставка</h3>
            <DeliveryDataInputs />
            <hr />
            <h3 className='checkout__title'>Оплата</h3>
            <PaymentDataInputs />
            <hr />
            <div className={styles.addComment}>
              {!commentAvailible && (
                <span
                  type='button'
                  className={styles.comment}
                  id='commentInvolver'
                  onClick={() => {
                    setCommentAvailible(true);
                  }}
                >
                  Додати коментарій
                </span>
              )}
              {commentAvailible && (
                <div className={styles.textareaContainer}>
                  <label className={styles.label}>Коментарій</label>
                  <Field
                    as='textarea'
                    className={styles.textarea}
                    name='comment'
                    placeholder='Коментарій'
                    rows={5}
                  />
                </div>
              )}
            </div>
            {/* Дезактивація кнопки, якщо кошик порожній */}
            <Button variant='dark' type='submit' disabled={!products.length}>
              Оформити замовлення
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default CheckoutAuth;
