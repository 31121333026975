import React, { useEffect, useState } from "react";
import "./Footer.scss";
import config from "../../config/globalConfig";
import Icons from "../Icons/Icons";
import { useHttp } from "../../hooks/http.hook";

const Footer = () =>  {
    const shopName = config.NAMESHOP;
    const [menu, setMenu] = useState([{name: "products", url: "products"}]);
    const { request } = useHttp();

    const getCategories = async() => {
            try {
                const data = await request('openpath/categories/getall/ismenu/', 'GET');
                if(data.status){
                    setMenu(data.data);
                }
            } catch (error) {
                console.log(error);
            }
        }
        useEffect(() => {
            getCategories();
        }, [])
    return (
        <>
            <footer className="site-footer">
                <div className="container">
                    <div className="row">
                    <div className="col-xs-6 col-md-4">
                        <h6>Про нас</h6>
                        <p className="text-justify">{shopName}.in.ua</p>
                    </div>

                    <div className="col-xs-6 col-md-4">
                        <h6>Категорії</h6>
                        <ul className="footer-links">
                            {menu.map((item, index) => {
                              return <li key={index}><a href={`/products/categories/${item.title}`}>{item.name}</a></li>
                            })}
                        {/* <li><a href="/products/category/men/">Чоловікам</a></li>
                        <li><a href="/products/category/women/">Жінкам</a></li>
                        <li><a href="/products/category/unisex">Унісекс</a></li>
                        <li><a href="/products/category/pouring">Розлив</a></li>
                        <li><a href="/products/category/aroma-accessories/">Арома-аксесуари</a></li>
                        <li><a href="/products/category/makeup/">Косметика</a></li>
                        <li><a href="/products/category/sell/">SALE</a></li> */}
                        </ul>
                    </div>

                    <div className="col-xs-6 col-md-4">
                        <h6>Корисне</h6>
                        <ul className="footer-links">
                        <li><a href="#">Про нас</a></li>
                        <li><a href="https://www.instagram.com/jadore_kremenchug?igsh=MXJqejZjdnFwNjR4cg==" target='blank'>Зв'язатися з нами</a></li>
                        <li><a href="#">Privacy Policy</a></li>
                        <li><a href="/blog">Блог</a></li>
                        <li><a href="#">Оплата і доставка</a></li>
                        </ul>
                    </div>
                    </div>
                    <hr/>
                </div>

                <div className="container">
                    <div className="row">
                    <div className="col-md-8 col-sm-6 col-xs-12">
                        <p className="copyright-text">Copyright &copy; {new Date().getFullYear()} All Rights Reserved by 
                            <a href="#" id="name_shop"> {shopName}</a>.
                        </p>
                    </div>
                    <div className="col-md-4 col-sm-6 col-xs-12">
                        <ul className="social-icons">
                            {/* <li><a className="facebook" href="#" target='blank' rel='noreferrer'>
                                <Icons className="icons" type='telegram'  color='#fff' />
                                </a></li> */}
                            <li><a className="dribbble" href="https://www.instagram.com/jadore_kremenchug?igsh=MXJqejZjdnFwNjR4cg==" target='blank' rel='noreferrer'>
                                <Icons className="icons" type='instagram'  color='#fff' />
                                </a>
                            </li>  
                        </ul>
                    </div>
                    </div>
                </div>
            </footer>
        </>
    );
}

export default Footer;