import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { replace } from '../../../utils/func';
import { Decimal } from 'decimal.js';
import {
  addProductToCartOperation,
  changeLocalCartOperation,
  decreaseCartProductQuantityOperation,
  deleteProductFromCartOperation,
} from '../../../store/cart/operations';
import { saveModalOrderAction } from '../../../store/modal/actions';
import styles from './CartItem.module.scss';
import { AuthContext } from '../../../context/AuthContext';
import defaultImg from '../../../theme/assets/images/pexels-rethaferguson-3059609.jpg';

const CartItem = ({ product, cartQuantity, cart }) => {
  const { previousPrice, currentPrice, quantity, _id: id, bottling, quantityMl } = product;
  const isAuth = useContext(AuthContext).isAuthenticated;
  const admin = useContext(AuthContext).permissions?.admin?.access;
  const dispatch = useDispatch();
  const [controlQuantity, setControlQuantity] = useState(cartQuantity);
  const [clickDisabled, setClickDisabled] = useState(false);

  const decrementQuantity = () => {
    if (!clickDisabled && controlQuantity > 1) {
      setClickDisabled(true);
      setControlQuantity(prev => prev - 1); // Оновлюємо локальну кількість
      dispatch(changeLocalCartOperation(id, 'decrease'));

      // if (isAuth) {
      //   dispatch(decreaseCartProductQuantityOperation(admin, id));
      // }

      setTimeout(() => {
        setClickDisabled(false);
      }, 500);
    }
  };

  const incrementQuantity = () => {
    const maxQuantity = bottling ? quantityMl : quantity;
    if (!clickDisabled && controlQuantity < maxQuantity) {
      setClickDisabled(true);
      setControlQuantity(prev => prev + 1); // Оновлюємо локальну кількість
      dispatch(changeLocalCartOperation(id, 'add'));

      // if (isAuth) {
      //   dispatch(addProductToCartOperation(admin, id));
      // }

      setTimeout(() => {
        setClickDisabled(false);
      }, 500);
    }
  };

  const deleteProduct = () => {
    dispatch(changeLocalCartOperation(id, 'delete'));
    window.location.reload();
    // if (isAuth) {
    //   dispatch(deleteProductFromCartOperation(admin, id, cart));
    // }
  };

  const calculatePrice = () => {
    let totalPrice = new Decimal(currentPrice).mul(controlQuantity); // Початково множимо на кількість

    if (product.discount && product.discount > 0) {
      const discount = new Decimal(product.discount).div(100);
      totalPrice = totalPrice.mul(new Decimal(1).minus(discount)); // Віднімаємо знижку
    } else if (product.categories?.discount?.quantity > 0) {
      const discount = new Decimal(product.categories.discount.quantity).div(100);
      totalPrice = totalPrice.mul(new Decimal(1).minus(discount)); // Віднімаємо знижку
    }

    return totalPrice.toDecimalPlaces(2).toString();
  };

  const closeCart = () => {
    dispatch(saveModalOrderAction(false));
  };

  const decrementDisabled = controlQuantity <= 1;
  const incrementDisabled = !bottling ? controlQuantity >= quantity : controlQuantity >= quantityMl;

  return (
    <li className={styles.container}>
      <div className={styles.itemWrapper_name}>
        <div className={styles.delete}>
          <span
            onClick={() => {
              deleteProduct();
            }}
            className={styles.deleteBtn}
          >
            &#128465;
          </span>
        </div>
        <NavLink to={`/products/item/${product._id}`} onClick={closeCart}>
          <img src={product.imageFirst ? product.imageFirst : `${defaultImg}`} className={styles.image} width={78} height={78} alt="product img" />
        </NavLink>
        <div className={styles.nameBlock}>
          <NavLink to={`/products/item/${product._id}`} onClick={closeCart}>
            <h4 className={styles.name}>{product.name}</h4>
          </NavLink>
          {previousPrice ? (
            <div className={styles.priceSales}>
              <div className={styles.currentPrice}>{replace(currentPrice)} грн</div>
              <div className={styles.previousPrice}>{replace(previousPrice)} грн</div>
            </div>
          ) : (
            <div className={styles.regularPrice}>{replace(currentPrice)} грн</div>
          )}
        </div>
      </div>
      <div className={styles.itemWrapper_count}>
        <div>
          <div className={styles.quantityBlock}>
            <button
              disabled={decrementDisabled}
              className={styles.quantityBtn}
              type="button"
              onClick={decrementQuantity}
            >
              -
            </button>
            <input
              className={styles.quantityInput}
              type="text"
              value={controlQuantity}
              readOnly
            />
            <button
              disabled={incrementDisabled}
              className={styles.quantityBtn}
              type="button"
              onClick={incrementQuantity}
            >
              +
            </button>
          </div>
          <span>{product.bottling ? ' мл' : ' шт'}</span>
          {controlQuantity >= (bottling ? quantityMl : quantity) && (
            <span className={styles.ended}>Вибачте, товару немає</span>
          )}
        </div>
        <div className={styles.price}>
          <span>{calculatePrice()} грн</span>
        </div>
      </div>
    </li>
  );
};

CartItem.propTypes = {
  product: PropTypes.object.isRequired,  // Продукт має бути об'єктом
  cartQuantity: PropTypes.number.isRequired,  // Кількість товару повинна бути числом
  cart: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),  // Кошик може бути об'єктом або масивом
};

CartItem.defaultProps = {
  cart: [],  // Якщо значення не передане, за замовчуванням використовуємо порожній масив
};

export default CartItem;
