import React, { useState } from 'react';
import { useFormik } from 'formik';
import { useHttp } from '../../../hooks/http.hook';
import { useMessageError, useMessageSuccess } from '../../../hooks/message.hook';
import { Form, Button} from 'react-bootstrap';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';

const RegistrationForm = ({ isAuth }) => {
    const { error, request, clearError } = useHttp();
    const messageError = useMessageError();
    const messageSuccess = useMessageSuccess();
    const [formState, setFormState] = useState(true);
    const navigate = useNavigate();

    const formik = useFormik({
      initialValues: {
        email: '',
        phone: '',
        password: '',
        gender: '',
      },
      validationSchema: Yup.object({
        phone: 
          Yup.string().matches(/^\+380\d{3}\d{2}\d{2}\d{2}$/, 'Неправильний формат номерy телефонa має починатися з +38, без () та пробілів'),
        password: Yup.string()
          .min(7, "Пароль повинен містити щонайменше 7 символів")
          .required("Обов'язкове поле"),
        email: Yup.string()
          .email("Неправильний формат електронної адреси")
          .matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, "Неправильний формат електронної адреси")
          .required("Обов'язкове поле"),
        gender: Yup.string()
          .oneOf(["Male", "Female"])
          .required("Обов'язкове поле")
      }),
      onSubmit: values => {
        clickSubmiting(values)
      }
    });
	const clickSubmiting = async (values) => {
    try {
      const data = await request("auth/signup/", "POST", { ...values });
      if(data.status){
        messageSuccess(data.message);
        navigate("/auth/success");
      }else{
        messageError("Помилка реєстрації")
      }
    } catch (e) {
      messageError(e)
    }
	};

	return (
        <div>
        <Form onSubmit={formik.handleSubmit}>
          <Form.Group controlId="phone" className='mb-3'>
            <Form.Label className='d-block text-start'>Телефон</Form.Label>
            <Form.Control
              name="phone"
              type="tel"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.phone}
              placeholder="+380"
            />
            <Form.Text className="text-danger">
              {formik.touched.phone && formik.errors.phone ? (
                <div className="text-danger">{formik.errors.phone}</div>
              ) : null}
            </Form.Text>
          </Form.Group>

          <Form.Group controlId="password" className='mb-3'>
            <Form.Label className='d-block text-start'>Пароль</Form.Label>
            <Form.Control
              name="password"
              type="password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.password}
              placeholder="Введіть пароль"
            />
            <Form.Text className="text-danger">
              {formik.touched.password && formik.errors.password ? (
                <div className="text-danger">{formik.errors.password}</div>
              ) : null}
            </Form.Text>
          </Form.Group>

          <Form.Group controlId="email" className='mb-3'>
            <Form.Label className='d-block text-start'>Електронна пошта</Form.Label>
            <Form.Control
              name="email"
              type="email"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
              placeholder="Введіть електронну пошту"
            />
            <Form.Text className="text-danger">
              {formik.touched.email && formik.errors.email ? (
                <div className="text-danger">{formik.errors.email}</div>
              ) : null}
            </Form.Text>
          </Form.Group>

          <Form.Group className='mb-3' controlId="gender">
            <Form.Label className='d-block text-start'>Стать</Form.Label>
            <Form.Select
              name="gender"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.gender}
            >
                <option value="-" >Виберіть</option>
                <option value="Male">Чоловіча</option>
                <option value="Female">Жіноча</option>
            </Form.Select>
            <Form.Text className="text-danger">
              {formik.touched.gender && formik.errors.gender ? (
                <div className="text-danger">{formik.errors.gender}</div>
              ) : null}
            </Form.Text>
          </Form.Group>

          <Button
            variant="dark"
            type="submit"
            onClick={values => setFormState(values)}
            // disabled={formState}
          >
            Реєстрація
          </Button>
        </Form>
    </div>
	);
};

export default RegistrationForm;
