import React, { useEffect, useState } from 'react';
import { useHttp } from '../../../../hooks/http.hook';
import { useMessageError, useMessageSuccess } from '../../../../hooks/message.hook';
import { useNavigate, useParams } from 'react-router-dom';
import styles from "./FormWrapper.module.scss";
import RequiredIndicator from '../../../../components/Forms/RequiredIndicator/RequiredIndicator';
import { Button } from 'react-bootstrap';

const FormWrapper = () => {
  const { request } = useHttp();
  const messageError = useMessageError();
  const messageSuccess = useMessageSuccess();
  const navigate = useNavigate();
  const { id } = useParams();
  const [permissions, setPermissions] = useState([{ _id: '' }]);
  const [roleForm, setRoleForm] = useState(() => {
    const defaultPermissions = [{ _id: '' }][0];
    return {
      adminAccess: false,
      customerAccess: false,
      name: '',
      permissionId: defaultPermissions._id,
    };
  });

  const getById = async () => {
    try {
      const data = await request(`admin/roles/getby/${id}`, "GET");
      if (data && data.status) {
        setPermissions(data.data.permissions);
        setRoleForm(prevForm => ({
          ...prevForm,
          name: data.data.name,
          adminAccess: data.data.permissions.admin.access || false,
          customerAccess: data.data.permissions.customer.access || false,
        }));
        messageSuccess(data.message);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getDefaultPermissions = async () => {
    try {
      const data = await request(`admin/roles/get/default/permissions`, "GET");
      if (data && data.status) {
        setPermissions(data.data.permissions);
        setRoleForm(prevForm => ({
            ...prevForm,
            name: data.data.name,
            adminAccess: data.data.permissions.admin.access || false,
            customerAccess: data.data.permissions.customer.access || false,
          }));
        messageSuccess(data.message);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (id) {
      getById();
    } else {
      getDefaultPermissions();
    }
  }, [id]);

  useEffect(() => {
    if (permissions && permissions.admin && permissions.admin.modules) {
      const adminModules = permissions.admin.modules;
      const adminModulesKeys = Object.keys(adminModules);
      const adminModulesValues = Object.values(adminModules);
  
      const adminModulesState = adminModulesKeys.reduce((acc, key, index) => {
        acc[`adminModule${key}`] = adminModulesValues[index].access;
        return acc;
      }, {});
  
      setRoleForm(prevForm => ({
        ...prevForm,
        ...adminModulesState
      }));
    }
  
    if (permissions && permissions.customer && permissions.customer.modules) {
      const customerModules = permissions.customer.modules;
      const customerModulesKeys = Object.keys(customerModules);
      const customerModulesValues = Object.values(customerModules);
  
      const customerModulesState = customerModulesKeys.reduce((acc, key, index) => {
        acc[`customerModule${key}`] = customerModulesValues[index].access;
        return acc;
      }, {});
  
      setRoleForm(prevForm => ({
        ...prevForm,
        ...customerModulesState
      }));
    }
  }, [permissions]);

  const handleToggleAdminModules = (isChecked) => {
    const updatedForm = { ...roleForm };
    updatedForm.adminAccess = isChecked
    Object.keys(updatedForm).forEach(key => {
      if (key.startsWith('adminModule')) {
        updatedForm[key] = isChecked;
      }
    });
    setRoleForm(updatedForm);
  };
  
  const handleToggleCustomerModules = (isChecked) => {
    const updatedForm = { ...roleForm };
    updatedForm.customerAccess = isChecked
    Object.keys(updatedForm).forEach(key => {
      if (key.startsWith('customerModule')) {
        updatedForm[key] = isChecked;
      }
    });
    setRoleForm(updatedForm);
  };

  const submitForm = async (e) => {
    e.preventDefault();
    try {
      const formData = roleForm;
      let data;
      if (!id) {
        data = await request(`admin/roles/create`, "POST", { ...formData });
      } else {
        data = await request(`admin/roles/edit/${id}`, "PUT", { ...formData });
      }
      if (data.status) {
        messageSuccess(id ? 'Роль відредаговано' : 'Роль успішно створено!');
        navigate('/admin/roles');
      } else {
        messageError(data.data.message);
      }
    } catch (e) {
      messageError(e);
    }
  };

  return (
    <div className="container">
      <h4 className="role_heading--page">{id ? "Редагувати" : "Додати"}<span> Роль</span></h4>
      <form onSubmit={submitForm}>
        <div className="role_menu--container">
          <div className="role_menu--item">
            <label><RequiredIndicator />Name: </label>
            <input
              value={roleForm.name}
              onChange={(e) => setRoleForm(prevForm => ({ ...prevForm, name: e.target.value }))}
              className="role_menu--item-field"
            />
          </div>
        </div>

        <div className="row mt-3">
          <div className="col">
            <h5>Admin Access</h5>
            <div className={`form-check form-switch ${styles.switch__wrapper}`}>
              <input
                checked={roleForm.adminAccess}
                onChange={(e) => {
                  setRoleForm(prevForm => ({ ...prevForm, adminAccess: e.target.checked }));
                  handleToggleAdminModules(e.target.checked);
                }}
                className="form-check-input"
                type="checkbox"
                id="adminAccess"
              />
              <label className="form-check-label" htmlFor="adminAccess">Admin access</label>
            </div>
            {permissions && permissions.admin && permissions.admin.modules &&
              <div className="admin-modules">
                {Object.entries(permissions.admin.modules).map(([moduleName, moduleData]) => (
                  <div key={moduleName}>
                    <div className={`form-check form-switch ${styles.switch__wrapper}`}>
                    <label className="form-check-label" htmlFor={`adminModule${moduleName}`}>{moduleName}</label>
                      <input
                        checked={roleForm[`adminModule${moduleName}`]}
                        onChange={(e) => setRoleForm(prevForm => ({ ...prevForm, [`adminModule${moduleName}`]: e.target.checked }))}
                        className="form-check-input"
                        type="checkbox"
                        id={`adminModule${moduleName}`}
                      />
                    </div>
                  </div>
                ))}
              </div>
            }
          </div>

          <div className="col">
            <h5>Customer Access</h5>
            <div className={`form-check form-switch ${styles.switch__wrapper}`}>
              <input
                checked={roleForm.customerAccess}
                onChange={(e) => {
                  setRoleForm(prevForm => ({ ...prevForm, customerAccess: e.target.checked }));
                  handleToggleCustomerModules(e.target.checked);
                }}
                className="form-check-input"
                type="checkbox"
                id="customerAccess"
              />
              <label className="form-check-label" htmlFor="customerAccess">Customer access</label>
            </div>
            {permissions && permissions.customer && permissions.customer.modules &&
              <div className="customer-modules">
                {Object.entries(permissions.customer.modules).map(([moduleName, moduleData]) => (
                  <div key={moduleName}>
                    <div className={`form-check form-switch ${styles.switch__wrapper}`}>
                    <label className="form-check-label" htmlFor={`customerModule${moduleName}`}>{moduleName}</label>
                      <input
                        checked={roleForm[`customerModule${moduleName}`]}
                        onChange={(e) => setRoleForm(prevForm => ({ ...prevForm, [`customerModule${moduleName}`]: e.target.checked }))}
                        className="form-check-input"
                        type="checkbox"
                        id={`customerModule${moduleName}`}
                      />
                    </div>
                  </div>
                ))}
              </div>
            }
          </div>
        </div>
        <div>
          <Button variant="dark" className='mb-3' type='submit'>{id ? "Save" : "Add"}</Button>
        </div>
      </form>
    </div>
  );
};

export default FormWrapper;
