import React, { useEffect, useState } from "react";
import styles from "./Products.module.scss";
import ProductsCards from "../../components/ProductsCards/ProductsCard";
import { NavLink, useParams } from "react-router-dom";
import Loader from "../../components/Loader/Loader";
import Pagination from "../../components/Pagination/Pagination";
import { useDispatch, useSelector } from "react-redux";
import { getProductsOperation } from "../../store/products/operations";
import { getProductsSelector } from "../../store/products/selectors";
import { getFiltersProductsSelector } from "../../store/filters/selectors";
import { getCategoriesMenuSelector } from "../../store/categories/selectors";
import { filtersProductsOperation } from "../../store/filters/operations";
import ProductsFilter from "../../components/ProductsFilter/ProductsFilter";

const Products = () => {
    const dispatch = useDispatch();
    const categoriesMenu = useSelector(getCategoriesMenuSelector);
    const filterProducts = useSelector(getFiltersProductsSelector);
    const products = useSelector(getProductsSelector);
    const [category, setCategory] = useState([]);
    const [selected, setSelected] = useState({});
    const params = useParams();
    const [startPage, setStartPage] = useState(1);
    const perPage = 30;
    const [refreshOrders, setRefreshOrders] = useState(true);
    const [sort, setSort] = useState(1);
    const [ordersFilter, setOrdersFilter] = useState({ perPage, startPage, sort, categories: params.categories, filter: { ...selected } });

    const sorting = (event) => {
        if (event === 'new') {
            setSort(event);
            setOrdersFilter({ ...ordersFilter, sort: 1 , sortField: 'updatedAt'});
        }
        else{
            setSort(event);
            setOrdersFilter({ ...ordersFilter, sort: event , sortField: 'currentPrice'});
        }
        setRefreshOrders(true);
    };

    const filter = (key, event) => {
        let updatedSelected;
        if (event == 0) {
            updatedSelected = { ...selected };
            delete ordersFilter.filter[key]
            delete updatedSelected[key];
        } else {
            updatedSelected = {
                ...selected,
                [key]: event
            };
        }
    
        const updatedOrdersFilter = {
            ...ordersFilter,
            filter: { ...ordersFilter.filter, ...updatedSelected }
        };

        setSelected(updatedSelected);
        setOrdersFilter(updatedOrdersFilter);
        localStorage.setItem("filtersProducts", JSON.stringify(ordersFilter));
        setRefreshOrders(true);
    };

    useEffect(() => {
        if(params.categories){
            dispatch(filtersProductsOperation(ordersFilter));
        }
    }, [refreshOrders, params.categories, startPage, dispatch, ordersFilter]);

    useEffect(() => {
        if(params.categories){
            dispatch(getProductsOperation(ordersFilter));
        }
    }, [refreshOrders, params.categories, startPage, dispatch, ordersFilter]);

    useEffect(() => {
        if (categoriesMenu.length > 0 && !!params?.categories) {
            const data = categoriesMenu.filter((item) => item.title === params.categories);
            setCategory(data[0]);
        }
    }, [categoriesMenu, params.categories]);
    // При монтажі компонента, завантажимо дані з localStorage, якщо вони є
    useEffect(() => {
        if (localStorage.getItem("filtersProducts")) {
            const savedOrdersFilter = JSON.parse(localStorage.getItem("filtersProducts"));
            if(savedOrdersFilter.categories != params.categories){
                savedOrdersFilter.filter = {};
            }
            savedOrdersFilter.categories = params.categories;
            setOrdersFilter(savedOrdersFilter);
        }
    }, [ params.categories]);
    useEffect(() => {
        localStorage.setItem("filtersProducts", JSON.stringify(ordersFilter));
    }, [ordersFilter, params.categories]);

    return (
        <>
            <section>
                <div className={styles.breadCrumbs}>
                    <NavLink className={styles.crumbsLink} to='/'>
                        Головна
                    </NavLink>
                    {(category && category?.name) && <>
                        <span className={styles.iconBreadcrumbs}>{}</span>
                        <span className={styles.crumbs}>{category?.name}</span>
                    </>}
                    {(category.length == 0) && <>
                        <span className={styles.iconBreadcrumbs}>{}</span>
                        <span className={styles.crumbs}>Всі товари</span>
                    </>}
                </div>
                <div className={`d-flex justify-content-between mb-3 ${styles.filter__block}`}>
                    <ProductsFilter filterProducts={filterProducts} filter={filter} selected={selected} ordersFilter={ordersFilter}/>
                    <div>
                        <div className={styles.filter}>
                            <select name="sort" id="sort" value={sort} onChange={(e) => { sorting(e.target.value) }}>
                                <option value={'new'}>Новинки</option>
                                <option value={-1}>За зменшенням ціни</option>
                                <option value={1}>За зростанням ціни</option>
                            </select>
                        </div>
                    </div>
                </div>
                
                <ProductsCards className="cards__list" products={products} categoriesMenu={categoriesMenu} />
                <Pagination perPage={perPage} startPage={startPage} setPage={setStartPage} productsQuantity={products.length} />
            </section>
        </>
    );
};

export default Products;