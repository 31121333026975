import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import CardItem from "./Card/CardItem";
import { getWishListSelector } from "../../store/wishList/selectors";
import { useSelector } from "react-redux";
import Loader from "../Loader/Loader";
import "./ProductsCards.scss";

const ProductsCards = ({ products, categoriesMenu }) => {
    const auth = useContext(AuthContext);
    const [productsCards, setProductsCards] = useState([]);
    const [wishProduct, setWishProduct] = useState({});
    const storeWishlist = useSelector(getWishListSelector);

    useEffect(() => {
        const wishlist = {};

        if (storeWishlist && storeWishlist?.products?.length > 0) {
            storeWishlist.products.forEach((prod) => {
                wishlist[prod._id] = true;
            });
        } else if (!storeWishlist && localStorage.getItem("wishlist")) {
            const localStorageWishlist = JSON.parse(localStorage.getItem("wishlist"));
            if (localStorageWishlist?.products?.length > 0) {
                localStorageWishlist.products.forEach((prod) => {
                    wishlist[prod._id] = true;
                });
            }
        }

        setWishProduct(wishlist);
    }, [storeWishlist]);

    useEffect(() => {
        if (products.length > 0) {
            const list_products = products.map((item) => (
                <div key={item._id} className="col custom-card-width mb-4">
                    <CardItem auth={auth} item={item} wishProduct={wishProduct} />
                </div>
            ));
            setProductsCards(list_products);
        }
    }, [products, wishProduct]);

    if (products.length === 0 || !categoriesMenu || categoriesMenu.length === 0) {
        return (
            <>
                <h1>Товари цієї категорії в дорозі...</h1>
            </>
        );
    }

    return (
        <div className="container">
            <div className="row justify-content-center">
                {products.length > 0 ? productsCards : <p>Товарів немає, ми вирішуємо цю проблему.</p>}
            </div>
        </div>
    );
};

export default ProductsCards;
