import React, { useEffect, useState } from "react";
import styles from "./MenuProducts.module.scss";
import { Link } from "react-router-dom";

const MenuProducts = ({ open, setOpen, menuList }) => {
  const [menuItems, setMenuItems] = useState([]);

  const handleClickMenu = () => {
    setOpen(false);
  };

  const generateMenu = () => {
    const links = menuList.map((item, index) => (
      <Link
        key={index}
        to={`/products/categories/${item.title}`}
        className={styles.itemLink}
        onClick={handleClickMenu}
      >
        {item.name}
      </Link>
    ));
    setMenuItems(links);
  };

  useEffect(() => {
    if (menuList.length > 0) {
      generateMenu();
    }
  }, [menuList]);

  return (
    <nav className={`${styles.menuProducts} ${open ? styles.openProducts : ''}`}>
      {menuItems}
    </nav>
  );
};

export default MenuProducts;
