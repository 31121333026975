import React from 'react';
import { Button } from 'react-bootstrap';

const ProductCard = ({ product, handleActive, handleEdit, handleDelete }) => {
    return (
        <div className="col-md-4 mb-4">
            <div className="card">
                
                <img src={product.imageFirst ? product.imageFirst : "https://img.freepik.com/free-psd/luxury-perfume-bottle-png-isolated-on-transparent-background_191095-9831.jpg?w=1380&t=st=1728899677~exp=1728900277~hmac=cd48b5adb80c0d1e32b4f9de203b62975f599c51781de3bf763f4ac6a5bb4f27"} className="card-img-top" style={{height:400 + 'px'}} alt="Product" />
                <div className="card-body">
                    <h5 className="card-title">{product.name}</h5>
                    <p className="card-text">
                        {!!product.sizes && <span>Розмір пляшки: {product.sizes} мл</span>}
                        {!!product.discount && <span>Знижка на товар: {product.discount}</span>}
                        {!!product.bottling && <span>Доступні мл: {product.quantityMl} мл</span>}
                        <strong>Кількість: </strong> {product.quantity} шт/мл<br />
                        {product.currentPrice && <strong>Актуальна ціна: {product.currentPrice} грн</strong>}<br />
                        {!!product.previousPrice && <span>Попередня ціна: {product.previousPrice} грн</span>}
                    </p>
                    <p className="card-text">
                        <strong>Категорія:</strong> {product.categories?.name}<br />
                        <strong>Статус:</strong> {product.enabled ? "Ввімкнений" : "Вимкнений"}
                    </p>
                    <Button variant={product.enabled ? "secondary" : "primary"} onClick={() => handleActive(product._id)}>
                        {product.enabled ? "Вимкнути" : "Ввімкнути"}
                    </Button>{' '}
                    <Button variant="secondary" onClick={() => handleEdit(product._id)}>Редагувати</Button>{' '}
                    <Button variant="danger" onClick={() => handleDelete(product._id)}>Видалити</Button>
                </div>
            </div>
        </div>
    );
};

export default ProductCard;