import React from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { replace } from '../../../../utils/func';
import styles from './ProductOrdersItem.module.scss';
import Decimal from 'decimal.js';
import defaultImg from '../../../../theme/assets/images/pexels-rethaferguson-3059609.jpg';

const PRODUCT_ROUTE = "products/item/";

const ProductOrdersItem = ({ product }) => {

  // Функція обчислення загальної суми для продукту на основі discountedPrice
  const calculateTotalPrice = () => {
    // Використовуємо discountedPrice, який вже має обчислену знижку
    let totalPrice = new Decimal(product.discountedPrice).mul(product.cartQuantity).toDecimalPlaces(2).toString();
    return totalPrice;
  };

  return (
    <div className={styles.productItem}>
      <div className={styles.productImage}>
        <NavLink to={`/${PRODUCT_ROUTE}${product.product._id}`}>
          <img src={product.product.imageFirst ? product.product.imageFirst : `${defaultImg}`} width={100} height={100} alt='productImage' />
        </NavLink>
      </div>
      <div>
        <NavLink to={`/${PRODUCT_ROUTE}${product.product._id}`}>
          <div className={`${styles.bold} ${styles.productName}`}>
            {product.product.name}, {product.product.color}
          </div>
        </NavLink>
        <div className={styles.productPrice}>
          <div>
            Ціна: <span className={styles.bold}>{replace(product.discountedPrice)} грн.</span>
          </div>
          <div>
            К-ть: {product.product.bottling 
            ? <span className={styles.bold}>{product.cartQuantity} мл.</span> 
            : <span className={styles.bold}>{product.cartQuantity} шт.</span>}
          </div>
          <div>
            Сума:{' '}
            <span className={styles.bold}>{calculateTotalPrice()} грн.</span>
          </div>
        </div>
      </div>
    </div>
  );
};

ProductOrdersItem.propTypes = {
  product: PropTypes.object.isRequired,
};

export default ProductOrdersItem;
