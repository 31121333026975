import React, { useState, useEffect } from 'react';
import { Table, Modal, Button } from 'react-bootstrap';
import { useHttp } from '../../../hooks/http.hook';
import { useMessageError, useMessageSuccess } from '../../../hooks/message.hook';
import FormWrapper from './FormWrapper/FormWrapper';
import Container from '../../../components/Container/Container';
import BurgerMenu from '../../../components/BurgerMenu/BurgerMenu';

const Brands = () => {
    const { request } = useHttp();
    const messageError = useMessageError();
    const messageSuccess = useMessageSuccess();
    const [showModal, setShowModal] = useState(false);
    const [showDel, setShowDel] = useState(false);
    const [delId, setDelId] = useState();
    const [selectedBrand, setSelectedBrand] = useState(null);
    const [brands, setBrands] = useState([]);

    const fetchBrands = async () => {
        try {
            const data = await request('admin/brands/getall', 'GET');
            setBrands(data.data);
        } catch (error) {
            messageError(error);
        }
    };

    useEffect(() => {
        fetchBrands();
    }, []);

    const handleDelete =  (id) => {
        setShowDel(true);
        setDelId(id)
    };
    const handleDeleteAccess = async () => {
        setShowDel(false);
        try {
            const data = await request(`admin/brands/delete/${delId}`, 'DELETE');
            messageSuccess(data.message);
            setDelId("")
            fetchBrands();
        } catch (error) {
            messageError(error);
        }
    };

    const handleCreate = () => {
        setShowModal(true);
        setSelectedBrand(null);
    };
    const handleEdit = (id) => {
        setSelectedBrand(id);
        setShowModal(true);
    };
    const handleCloseModal = (success = false) => {
        setShowModal(false);
        if(success) {fetchBrands();}
    };
    const handleCloseDel = () => {
        setShowDel(false);
        setDelId("")
    }

    return (
        <>
            <Container>
                <BurgerMenu />
                <h2>Всі Бренди</h2>
                <p>Kількість: <span>{brands.length}</span></p>
                <div className='d-flex justify-content-end'>
                    <Button variant="dark" className='mb-3' onClick={() => handleCreate()}>Створити</Button>{' '}
                </div>
                <div className='table__wrapper'>
                    <Table striped bordered hover className='table__main'>
                        <colgroup>
                            <col className="low" />
                            <col className="middle" />
                            <col className="middle" />
                            <col className="middle" />
                            <col className="middle" />
                        </colgroup>
                        <thead className='table__head'>
                            <tr>
                                <th>#</th>
                                <th>Назва</th>
                                <th>Картинка</th>
                                <th>Статус</th>
                                <th>Взаємодія</th>
                            </tr>
                        </thead>
                        <tbody>
                            {brands.length > 0 && brands.map((brand, index) => (
                                <tr key={brand._id}>
                                    <td>{index + 1}</td>
                                    <td>{brand.name}</td>
                                    <td><div className="product__img"><img src={brand.imageUrl} alt="Image"/></div></td>
                                    <td>{brand.active ? "Ввімкнений" : "Вимкнений" }</td>
                                    <td>
                                        <Button variant="secondary" onClick={() => handleEdit(brand._id)}>Редагування</Button>{' '}
                                        <Button variant="danger" onClick={() => handleDelete(brand._id)}>Видалити</Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
                            {/* Modal for editing brand */}
                <Modal show={showModal} onHide={handleCloseModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>{selectedBrand ? "Редагування" : "Створення"} категорії</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {selectedBrand && <FormWrapper create={false} edit={true} id={selectedBrand} onSubmit={handleCloseModal} close={handleCloseModal}/>}
                        {!selectedBrand && <FormWrapper create={true} edit={false} id={selectedBrand} onSubmit={handleCloseModal} close={handleCloseModal}/>}
                    </Modal.Body>
                </Modal>
                <Modal show={showDel} onHide={handleCloseDel}>
                    <Modal.Header closeButton>
                        <Modal.Title>Видалити</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Button variant="danger" onClick={() => handleDeleteAccess()}>Видалити</Button>
                    </Modal.Body>
                </Modal>
            </Container>
        </>
    );
};

export default Brands;
