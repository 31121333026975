import React, { useContext, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from 'react-bootstrap';
import { getLocalCartSelector } from '../../store/cart/selectors';
import { saveModalOrderAction } from '../../store/modal/actions';
import { addProductToCartOperation } from '../../store/cart/operations';
import { cartTotalPriceAction, saveLocalCartAction } from '../../store/cart/actions';
import { calculateTotalPrice } from '../../utils/func';
import { AuthContext } from "../../context/AuthContext";

const AddToCartButton = ({ admin, product, id, currentPrice, ml }) => {
  const [isCart, setIsCart] = useState(false); // Локальний стан

  const dispatch = useDispatch();
  const localCart = useSelector(getLocalCartSelector);

  // Функція для перевірки, чи товар вже є в кошику
  const isProductInCart = (cart, productId) => {
    if (Array.isArray(cart)) {
      // Якщо товар знайдено у кошику, повертаємо true
      return cart.some(item => item.product._id === productId);
    }
    // Якщо кошик не є масивом (можливо, він порожній), повертаємо false
    return false;
  };

  // Перевірка, чи товар є в кошику кожного разу, коли змінюється localCart
  useEffect(() => {
    setIsCart(isProductInCart(localCart, product._id));
  }, [localCart, product._id]);

  const openCart = () => {
    dispatch(saveModalOrderAction(true));
  };

  const addToCart = () => {
    let productCart = [...localCart]; // Створюємо копію кошика

    const existingProductIndex = productCart.findIndex(item => item.product._id === product._id);

    if (existingProductIndex !== -1) {
      // Якщо товар вже є в кошику, збільшуємо його кількість
      if (product.bottling) {
        productCart[existingProductIndex].cartQuantity += ml || 1;
      } else {
        productCart[existingProductIndex].cartQuantity += 1;
      }
    } else {
      // Якщо товару немає в кошику, додаємо новий товар
      const newProduct = product.bottling 
        ? { bottling: product.bottling, cartQuantity: ml || 1, product: product, currentPrice }
        : { cartQuantity: 1, product: product, currentPrice };

      productCart.push(newProduct);
    }

    // Оновлюємо localStorage
    localStorage.setItem('cart', JSON.stringify(productCart));

    // Оновлюємо загальну ціну
    const totalPrice = calculateTotalPrice(productCart, false);
    dispatch(cartTotalPriceAction(totalPrice));

    // Зберігаємо оновлений кошик в Redux
    dispatch(saveLocalCartAction(productCart));

    setIsCart(true); // Оновлюємо локальний стан
  };

  return (
    <>
      {!product.bottling && (
        <div>
          {isCart ? (
            <Button onClick={openCart} variant='dark' disabled={product.quantity === 0}>В кошику</Button>
          ) : (
            <Button onClick={addToCart} variant='dark' disabled={product.quantity === 0}>Додати в кошик</Button>
          )}
        </div>
      )}
      {product.bottling && (
        <div>
          {isCart ? (
            <Button onClick={openCart} variant='dark' disabled={product.quantityMl === 0}>В кошику</Button>
          ) : (
            <Button onClick={addToCart} variant='dark' disabled={product.quantityMl === 0}>Додати в кошик</Button>
          )}
        </div>
      )}
    </>
  );
};

export default AddToCartButton;
