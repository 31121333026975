
  import React, { useState, useEffect } from 'react';

  const UpArrow = () => {
    const [isVisible, setIsVisible] = useState(false);
  
    // Функція для прокручування на верх
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth', // Додаємо плавний перехід
      });
    };
  
    // Функція для визначення, чи користувач прокрутив нижче одного екрану
    const toggleVisibility = () => {
      if (window.scrollY > window.innerHeight) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };
  
    // Використовуємо useEffect для моніторингу прокрутки
    useEffect(() => {
      window.addEventListener('scroll', toggleVisibility);
      return () => window.removeEventListener('scroll', toggleVisibility);
    }, []);
  
    return (
      <>
        {isVisible && (
          <button
            onClick={scrollToTop}
            style={{
              position: 'fixed',
              bottom: '20px',
              right: '20px',
              backgroundColor: '#000',
              color: '#fff',
              border: 'none',
              borderRadius: '50%',
              width: '40px',
              height: '40px',
              cursor: 'pointer',
              fontSize: '20px',
            }}
          >
            ^
          </button>
        )}
      </>
    );
  };
  
  export default UpArrow;
  